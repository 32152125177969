import React, { useCallback, useState, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import { ButtonGroup, Button } from "@mui/material";
import _ from "lodash";
import MaterialTable from "../../../components/Table";

import { API, generateTableCallbacks } from "../../../utils/api";
import ConsortiumBulkUploadDialog from "./ConsortiumBulkUploadDialog";

export default function ExclusionsTable({
  study,
  direction,
  level,
  url,
  editUrl,
  columns,
  title,
  apiCallback,
  filterPanel,
  genericEditActions = false,
  setSubmitMessage,
}) {
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 5,
    pageIndex: 0,
  });
  const mountedRef = useRef(true);

  const getData = useCallback(
    (params = null) => {
      setLoading(true);
      API.request({
        url,
        method: genericEditActions ? "GET" : "POST",
        data: {
          page_idx: params.page_idx,
          limit: params.limit,
          filter_by: {
            ..._.pickBy(params?.filter_by, (value) => value !== ""),
            firm_name: params?.filter_by?.firm_name
              ? `~${params.filter_by.firm_name}`
              : undefined,
          },
        },
        params: {
          page_idx: params.page_idx,
          limit: params.limit,
          filter_by: {
            ..._.pickBy(params?.filter_by, (value) => value !== ""),
            firm_name: params?.filter_by?.firm_name
              ? `~${params.filter_by.firm_name}`
              : undefined,
          },
        },
      })
        .then((response) => {
          setData(response.data);

          setLoading(false);
        })
        .catch(() => {
          if (!mountedRef.current) return;
          const rows = [];
          setData({
            rows,
            total: 50,
          });
          setLoading(false);
        });
    },
    [genericEditActions, url],
  );

  const stableColumns = columns.map((column) => ({ ...column }));

  return (
    <div style={{ width: "100%" }}>
      {React.createElement(filterPanel, {
        filters,
        setFilters,
        getData: () => {
          setPagination((prev) => ({
            pageSize: prev.pageSize,
            pageIndex: 0,
          }));

          getData({
            page_idx: 0,
            limit: pagination.pageSize,
            filter_by: filters,
          });
        },
        handleResetFilters: () => {
          setFilters((prevFilters) => _.mapValues(prevFilters, () => ""));
          setTimeout(
            setPagination({ pageSize: pagination.pageSize, pageIndex: 0 }),
          );
          getData({ page_idx: 0, limit: pagination.pageSize });
        },
      })}
      <MaterialTable
        columns={stableColumns}
        data={data?.rows || []}
        title={title}
        manualPagination
        state={{
          pagination,
          isLoading: loading,
        }}
        rowCount={data?.total}
        onPaginationChange={(updater) => {
          const newPage = updater(pagination);
          getData({
            page_idx: newPage.pageIndex,
            limit: newPage.pageSize,
            filter_by: filters,
          });
          setPagination(newPage);
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <ButtonGroup>
            <Button
              variant="contained"
              onClick={() => table.setCreatingRow(true)}
              style={{ marginRight: 10 }}
              endIcon={<AddIcon />}
            >
              Add New
            </Button>
            <Button
              variant="contained"
              onClick={() => setBulkUploadDialogOpen(true)}
              endIcon={<UploadIcon />}
            >
              Bulk Upload
            </Button>
          </ButtonGroup>
        )}
        editable={generateTableCallbacks(
          editUrl || url,
          null,
          null,
          data.rows || [],
          (success, response) => {
            apiCallback(success, response);
            setTimeout(
              setPagination({ pageSize: pagination.pageSize, pageIndex: 0 }),
              0,
            );
            getData({
              page_idx: 0,
              limit: pagination.pageSize,
              filter_by: filters,
            });
          },
          setLoading,
          level,
        )}
      />

      <ConsortiumBulkUploadDialog
        open={bulkUploadDialogOpen}
        setOpen={setBulkUploadDialogOpen}
        setSubmitMessage={setSubmitMessage}
        level={level}
        study={study}
        direction={direction}
      />
    </div>
  );
}
