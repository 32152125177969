import React, { forwardRef, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";

import CSVUpload from "../../../components/input/CSVUpload";
import useSubmitTaskHandler from "../../../hooks/useSubmitTaskHandler";
import { lmsTaskTypes } from "../../tasks/create_wizard/TaskMapping";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "center",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.primary.light,
  },
  confirmButton: {
    color: theme.palette.text.onBackground,
    backgroundColor: theme.palette.secondary.light,
  },
  addIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ConsortiumBulkUploadDialog({ open, setOpen, study, direction, level, setSubmitMessage }) {
  const classes = useStyles();
  const [csv, setCsv] = useState({});
  const [taskId, setTaskId] = useState("");
  const bulkUploadTaskID = "preprocessing_dd_bulk_upload_consortium_exclusions";
  const bulkUploadTask = lmsTaskTypes.find((task) => task.id === bulkUploadTaskID);

  const taskConfig = {
    study,
    direction,
    level,
  };
  // console.log("taskconfig: ",taskConfig)

  const submitHandler = useSubmitTaskHandler({
    id: taskId,
    setTaskId,
    client: "ALL CLIENTS",
    action: bulkUploadTask.action,
    studyType: study,
    taskType: bulkUploadTask.id,
    taskConfig,
    title: bulkUploadTask.label,
    csv,
    setSubmitMessage,
    redirectToDetails: true,
  });

  // console.log("submit: ",submitHandler)

  const supportedFileTypes = useMemo(() => ["xlsx", "xls"], []);
  const fileTypeValidated = useMemo(() => {
    const fileExtention = csv.attachment?.name.split(".").pop();
    return supportedFileTypes.includes(fileExtention);
  }, [csv?.attachment, supportedFileTypes]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
        Bulk Upload
      </DialogTitle>
      <DialogContent dividers>
        <div><Alert severity="warning" style={{ marginBottom: 20 }}>This will fully replace the table</Alert></div>
        {(csv?.attachment && !fileTypeValidated) && (
          <div><Alert severity="error" style={{ marginBottom: 20 }}>{`File type not supported. Please use one of the following file types: ${supportedFileTypes.join(", ")}`}</Alert></div>
        )}
        <CSVUpload
          onChange={(update) => {
            setCsv({ attachment: update.csv });
          }}
          value={{ csv }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { submitHandler(true, { setSubmitMessage }, taskConfig); setOpen(false); }}
          disabled={!csv?.attachment || !fileTypeValidated}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
